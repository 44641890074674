import React from "react";
import styled from "styled-components";

const StyledBurger = styled.div`
  position: relative;
  width: 2rem;
  height: 2rem;
  top: 1%;
  left: 3%;
  display: none;
  @media (max-width: 630px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  @media screen and (max-width: 630px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? "white" : "white")};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "translateX(100%)" : "translateX(0)")};
      opacity: ${({ open }) => (open ? 0 : 1)};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ open, toggleMenu }) => {
  return (
    <StyledBurger open={open} onClick={toggleMenu}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

export default Burger;
