import React, { useState } from "react";
import "./NavBar.css";
import Burger from "./Burger";
import RightNav from "./RightNav";

const NavBar = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <header className="navigation__desktop">
        <div className="logo-soma">
          <a href="#home">
            <img src="/images/logo-soma.svg" alt="logo-soma" />
          </a>
        </div>
        <nav className="nav__desktop">
          <div className="nav-text1">
            <a href="#clients">Clients</a>
          </div>
          <div className="nav-text2">
            <a href="#publishing">Publishing Network</a>
          </div>
          <div className="nav-text3">
            <a href="#services">Advertising Services</a>
          </div>
          <div className="nav-text4">
            <a href="#formular">Contact</a>
          </div>
        </nav>
        <div className="get-offer__nav">
          <a href="#formular">
            <img src="/images/GetStartedWhite.svg" alt="Get Started White" />
          </a>
        </div>
      </header>
      <nav className="burger-mobile">
        <Burger
          open={open}
          toggleMenu={() => {
            setOpen(!open);
          }}
        />
        <div className="logo-burger">
          <a href="#home">
            <img src="/images/logo-soma.svg" alt="logo" />
          </a>
        </div>
      </nav>
      <RightNav
        open={open}
        toggleMenu={() => {
          setOpen(!open);
        }}
        closeMenu={() => {
          setOpen(false);
        }}
      />
    </>
  );
};

export default NavBar;
