import React from "react";
import styled from "styled-components";
import Burger from "./Burger";

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 20;
  list-style: none;
  display: none;
  flex-flow: row nowrap;
  li {
    padding-bottom: 3%;
    margin-left: 10%;
    margin-bottom: 10%;
    width: 80%;
  }
  .lista-buton {
    border-bottom: none;
  }
  .lista-buton img {
    width: 40%;
  }
  @media (max-width: 630px) {
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    background-color: rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(35px);
    position: fixed;
    transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    transition: transform 0.3s ease-in-out;
    li {
      color: white;
      font-weight: bold;
    }
    li a {
      text-decoration: none;
      color: white;
      font-family: Poppins;
    }
    .first-element {
      margin-top: 20%;
    }
    .logo-burger__inside {
      width: 30%;
      margin: 2% auto;
    }
    .logo-burger__inside img {
      width: 100%;
    }
    .lista-buton {
      display: flex;
      flex-direction: column;
      margin-left:7%;
    }
    .lista-buton img{
      width:100%; 
      margin-bottom:8%;
    }
    .getstarted--nav{
      width:50%;
    }
    .contactsales--nav{
      text-align:center;
      width:60%
    }
    }
  }
`;

const RightNav = ({ open, toggleMenu, closeMenu }) => {
  return (
    <Ul open={open}>
      <Burger open={open} toggleMenu={toggleMenu} />
      <div className="logo-burger__inside">
        <a onClick={closeMenu} href="#home">
          <img src="/images/logo-soma.svg" alt="logo" />
        </a>
      </div>
      <li className="first-element">
        <a onClick={closeMenu} href="#clients">
          Clients
        </a>
      </li>
      <li>
        <a onClick={closeMenu} href="#publishing">
          Publishing Network
        </a>
      </li>
      <li>
        <a onClick={closeMenu} href="#services__mobile">
          Advertising Services
        </a>
      </li>
      <li className="lista-buton">
        <a onClick={closeMenu} href="#formular" className="getstarted--nav">
          <img src="/images/GetStarted.svg" alt="Get Started" />
        </a>
        <a onClick={closeMenu} className="contactsales--nav" href="#formular">
          CONTACT SALES<span className="arrow--nav">&#10230;</span>
        </a>
      </li>
    </Ul>
  );
};

export default RightNav;
