import React from "react";
import "./Services.css";

const Services = () => {
  return (
    <>
      <div id="services" className="container-services">
        <div className="left">
          <img
            className="fundal"
            src="/images/image-services.webp"
            alt="Imagine services"
          />
        </div>
        <div className="right">
          <h2 className="title-services">Advertising services</h2>
          <div className="small-left">
            <ul>
              <li className="lista1">
                <img className="icon1" src="/images/icon1.webp" alt="icon 1" />
                <div className="container-text1">
                  <p className="text-lista1">
                    <b>Display advertising campaign</b>
                    <br />
                    <i>(IAB)</i>
                  </p>
                  <a
                    href="https://www.iab.com/wp-content/uploads/2019/04/IABNewAdPortfolio_LW_FixedSizeSpec.pdf?fbclid=IwAR224uLW2YiSImMXTjNJpA0QzinmYVWAzq1A3nd27rG8EqZeAlWDK5uaDyw"
                    target="_blank"
                    rel="noreferrer"
                    className="link1--services"
                  >
                    View formats
                  </a>
                </div>
              </li>
              <li className="lista2">
                <img className="icon2" src="/images/icon2.webp" alt="icon 2" />
                <div className="container-text2">
                  <p className="text-lista2">
                    <b>Advertising tehnology</b>
                    <br />
                    <i>(AdOcean)</i>
                  </p>
                  <a
                    href="https://demo.adocean-global.com/?fbclid=IwAR1FZzvB2lTi1Erw2AFY-yBVT6qJtm9maX1hZCJiZXzu3DZA69PPKYGrelw"
                    target="_blank"
                    rel="noreferrer"
                    className="link2--services"
                  >
                    View formats
                  </a>
                </div>
              </li>
              <li className="lista3">
                <img className="icon3" src="/images/icon3.webp" alt="icon 3" />
                <p className="text-lista3">
                  <b>Advertising campaign</b>
                  <br />
                  <i>(Standard or Premium package)</i>
                </p>
              </li>
              <li className="lista4">
                <img className="icon4" src="/images/icon4.webp" alt="icon 4" />
                <p className="text-lista4">
                  <b>Brand Awareness</b>
                </p>
              </li>
              <li className="lista5">
                <img className="icon5" src="/images/icon5.webp" alt="icon 5" />
                <p className="text-lista5">
                  <b>Custom Campaign</b>
                </p>
              </li>
              <li className="lista6">
                <img className="icon6" src="/images/icon6.webp" alt="icon 6" />
                <p className="text-lista6">
                  <b>Google, Tabola, Facebook</b>
                </p>
              </li>
              <li className="lista7">
                <img className="icon7" src="/images/icon7.webp" alt="icon 7" />
                <p className="text-lista7">
                  <b>Full Service Campaign</b>
                </p>
              </li>
            </ul>
          </div>
          <div className="links--services">
            <a href="#formular" className="getstartedlink--services">
              <img
                className="getstarted--services"
                src="/images/GetStarted.svg"
                alt="Buton Get Started services"
              />
            </a>
            <a className="contactsales--services" href="#formular">
              CONTACT SALES<span className="arrow--services">&#10230;</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
