import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="container-footer">
      <div className="email-footer">
        <img className="email" src="/images/email.webp" alt="email footer" />
        <p className="email-text">SALES@SOMASOCIAL.COM</p>
      </div>
      <div className="pin-footer">
        <img src="/images/pin.webp" alt="pin footer" className="second-image" />
        <p className="first-text">
          Office location:
          <br /> 8 Ing. Pandele Tarusanu Street,Bucharest, Romania
        </p>
      </div>
      <div className="link-footer">
        <img
          className="link"
          src="/images/broken-link.webp"
          alt="broken link"
        />
        <a
          className="link-soma__footer"
          href="https://www.somasocial.com"
          target="_blank"
          rel="noreferrer"
        >
          WWW.SOMASOCIAL.COM
        </a>
      </div>
    </div>
  );
};

export default Footer;
