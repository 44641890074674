import React from "react";
import "./App.css";
import Clients from "./components/Clients";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Home from "./components/Home";
import NavBar from "./components/NavBar";
import Network from "./components/Network";
import Services from "./components/Services";

function App() {
  return (
    <div className="App">
      <NavBar />
      <Home />
      <Clients />
      <Network />
      <Services />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
