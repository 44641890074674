import React from "react";
import Slider from "react-slick";
import "./Clients.css";

const images = [
  "/images/logo1.svg",
  "/images/logo2.svg",
  "/images/logo3.svg",
  "/images/logo4.svg",
  "/images/logo5.svg",
  "/images/logo6.svg",
  "/images/logo7.svg",
  "/images/logo8.svg",
  "/images/logo9.svg",
  "/images/logo10.svg",
  "/images/logo11.svg",
  "/images/logo12.svg",
  "/images/logo13.svg",
  "/images/logo14.svg",
  "/images/logo15.svg",
  "/images/logo16.svg",
  "/images/logo17.svg",
  "/images/logo18.svg",
  "/images/logo19.svg",
  "/images/logo20.svg",
  "/images/logo21.svg",
  "/images/logo22.svg",
  "/images/logo23.svg",
  "/images/logo24.svg",
  "/images/logo25.svg",
  "/images/logo26.svg",
];

const Clients = () => {
  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    speed: 8000,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: true,
    swipeToSlide: true,
    dots: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
        },
      },
    ],
  };
  return (
    <div id="clients" className="container-clients">
      <h2 className="title-clients">
        TRUSTED BY THE BIGGEST BRANDS IN ROMANIA
      </h2>
      <Slider {...settings} arrows={false} className="carusel">
        {images.map((img, index) => (
          <div key={index}>
            <img className="imagine-carusel" src={img} alt={img} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Clients;
