import React, { useState } from "react";
import "./Contact.css";

const ReCaptchaSiteKey = "6LcqkVwjAAAAAIOYyWONsSiGcGqpvjJvu2VqeQZG";

const Contact = () => {
  const [isSending, setIsSending] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    if (isSending) {
      return;
    }

    setIsSending(true);

    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute(ReCaptchaSiteKey, { action: "submit" })
        .then(async (token) => {
          const formData = new FormData(event.target);

          formData.append("g-recaptcha-response", token);

          try {
            const response = await fetch(`/form-submission.php`, {
              method: "POST",
              body: formData,
            });

            const jsonResponse = await response.json();

            if (jsonResponse.success === true) {
              alert("Cererea a fost trimisa cu success");
            } else {
              alert("A avut loc o eroare. Va rugam sa incercati mai tarziu.");
            }
          } catch (e) {
            console.error(e);
            alert("A avut loc o eroare. Va rugam sa incercati mai tarziu.");
          }

          event.target.reset();
          setIsSending(false);
        });
    });
  };

  return (
    <div className="container__form">
      <h1 id="formular" className="title__contact">
        Contact us!
      </h1>

      <form className="form" onSubmit={handleSubmit}>
        <div className="first-row">
          <div className="nume">
            <input
              type="text"
              placeholder="Name:"
              tabIndex="1"
              name="name"
              required
            />
            <input
              type="email"
              placeholder="E-mail:"
              tabIndex="2"
              name="email"
              required
            />
          </div>
        </div>
        <div className="publicatii">
          <textarea
            placeholder="Message:"
            tabIndex="3"
            name="messages"
            required
          />
        </div>
        <label className="checkbox">
          <input type="checkbox" required />I consent with with the processing
          of my personal data
        </label>
        <div className="container-button">
          <button className="buton-submit" type="submit" disabled={isSending}>
            {isSending ? (
              <span className="form__loader"></span>
            ) : (
              <img src="/images/buton-send.svg" alt="buton send" />
            )}
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
