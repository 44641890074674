import React, { useState } from "react";
import "./Network.css";
import { motion, AnimatePresence } from "framer-motion";

const data = [
  {
    id: 0,
    category: "generale",
    link: "stiripesurse.ro",
    logo: "/images/logo-stiripesurse.png",
  },
  {
    id: 1,
    category: "generale",
    link: "blacknews.ro",
    logo: "/images/logo-blacknews.png",
  },
  {
    id: 2,
    category: "generale",
    link: "revistablogurilor.ro",
    logo: "/images/logo-revistablogurilor.png",
  },
  {
    id: 3,
    category: "generale",
    link: "ecopolitic.ro",
    logo: "/images/logo-ecopolitic.png",
  },
  {
    id: 4,
    category: "generale",
    link: "pressonline.ro",
    logo: "/images/logo-pressonline.png",
  },
  {
    id: 5,
    category: "generale",
    link: "newsbucuresti.ro",
    logo: "/images/logo-newsbucuresti.png",
  },
  {
    id: 6,
    category: "financial",
    link: "bugetul.ro",
    logo: "/images/logo-bugetul.png",
  },
  {
    id: 7,
    category: "financial",
    link: "money.ro",
    logo: "/images/logo-money.png",
  },
  {
    id: 8,
    category: "sport",
    link: "sportbull.ro",
    logo: "/images/logo-sportbull.png",
  },
  {
    id: 9,
    category: "sport",
    link: "sportalert.ro",
    logo: "/images/logo-sportalert.png",
  },
  {
    id: 10,
    category: "sport",
    link: "sportpesurse.ro",
    logo: "/images/logo-sportpesurse.png",
  },
  {
    id: 11,
    category: "divertisment",
    link: "kmkz.ro",
    logo: "/images/logo-kmkz.png",
  },
  {
    id: 12,
    category: "divertisment",
    link: "cronicacarcotasilor.ro",
    logo: "/images/logo-cronica.png",
  },
  {
    id: 13,
    category: "divertisment",
    link: "comentacii.ro",
    logo: "/images/comentacii-logo.png",
  },
  {
    id: 14,
    category: "lifestyle",
    link: "exquis.ro",
    logo: "/images/logo-exquis.png",
  },
  {
    id: 15,
    category: "lifestyle",
    link: "toptabu.ro",
    logo: "/images/logo-tabu.png",
  },
];

const Network = () => {
  const [websiteType, setWebsiteType] = useState("generale");

  const filterButton = (value) => setWebsiteType(value);

  return (
    <div className="container-network" id="publishing">
      <h2 className="title__network">OUR PUBLISHING NETWORK</h2>
      <div className="container-buttons">
        <div
          onClick={filterButton.bind(null, "generale")}
          className={`first-button ${
            websiteType === "generale" ? "clicked-button" : ""
          }`}
        >
          <span>General news</span>
        </div>
        <div
          onClick={filterButton.bind(null, "financial")}
          className={`second-button ${
            websiteType === "financial" ? "clicked-button" : ""
          }`}
        >
          <span>Financial</span>
        </div>
        <div
          onClick={filterButton.bind(null, "sport")}
          className={`third-button ${
            websiteType === "sport" ? "clicked-button" : ""
          }`}
        >
          <span>Sport</span>
        </div>
        <div
          onClick={filterButton.bind(null, "divertisment")}
          className={`fourth-button ${
            websiteType === "divertisment" ? "clicked-button" : ""
          }`}
        >
          <span>Enterteniment</span>
        </div>
        <div
          onClick={filterButton.bind(null, "lifestyle")}
          className={`fifth-button ${
            websiteType === "lifestyle" ? "clicked-button" : ""
          }`}
        >
          <span>Lifestyle</span>
        </div>
      </div>
      <motion.div layout className="websites">
        <AnimatePresence>
          {data
            .filter((item) => item.category === websiteType)
            .map((website) => (
              <motion.div
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                layout
                className="website"
                key={website.id}
              >
                <div className="logo__network">
                  <img src={website.logo} alt="website logo" />
                </div>
                <div className="website-link">
                  <a
                    href={"https://www." + website.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {"www." + website.link}
                  </a>
                </div>
              </motion.div>
            ))}
        </AnimatePresence>
      </motion.div>
      <div className="links--network">
        <a className="getstarted--network" href="#formular">
          <img
            className="get-negru__network"
            src="/images/GetStarted.svg"
            alt="Get Offer"
          />
        </a>
        <a className="contactsales--network" href="#formular">
          CONTACT SALES<span className="arrow--network">&#10230;</span>
        </a>
      </div>
    </div>
  );
};

export default Network;
