import React from "react";

const Card = (props) => {
  return (
    <div className={props.className}>
      <div>{props.number}</div>
      <h3>{props.title}</h3>
    </div>
  );
};

export default Card;
