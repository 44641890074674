import React from "react";
import "./Home.css";
import CountUp from "react-countup";
import Card from "../components/UI/Card";

const Home = () => {
  return (
    <div id="home" className="container-firstpart">
      <h2 className="text__home1">We are here to build your future!</h2>
      <div className="cards">
        <Card
          className="card card1"
          title="Monthly Users"
          number={<CountUp end={7} duration={2} suffix=".5M" />}
        />
        <Card
          className="card card2"
          title="Monthly Pageviews"
          number={<CountUp end={61} duration={2} suffix="M" />}
        />
        <Card
          className="card card3"
          title="Facebook Followers"
          number={<CountUp end={3} duration={2} suffix=".5M" />}
        />
        <Card
          className="card card4"
          title="Advertorials Published"
          number={
            <CountUp
              end={
                1338 +
                Math.floor(
                  (new Date().getTime() - new Date("2022-12-10").getTime()) /
                    (24 * 60 * 60 * 1000)
                ) *
                  1
              }
              duration={2}
            />
          }
        />
        <Card
          className="card card5"
          title="Ad Campaigns"
          number={<CountUp end={969} duration={2} />}
        />
      </div>
      <div className="links--home">
        <a href="#formular" className="getstartedlink--home">
          <img
            className="getstarted--home"
            src="/images/GetStarted.svg"
            alt="Buton Get Started home"
          />
        </a>
        <a className="contactsales--home" href="#formular">
          CONTACT SALES<span className="arrow--home">&#10230;</span>
        </a>
      </div>
    </div>
  );
};

export default Home;
